import { Switch, Route, withRouter } from "react-router"

import HomePage from './pages/Home'
import LoginPage from './pages/Login'
import ForbiddenPage from './pages/Forbidden'
import NotFoundPage from './pages/NotFound'

const RouteView = () => {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/403" component={ForbiddenPage} />
      <Route component={NotFoundPage} />
    </Switch>
  )
}

export default withRouter(RouteView)