import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GavelIcon from '@mui/icons-material/Gavel';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import SchoolIcon from '@mui/icons-material/School';
import QuizIcon from '@mui/icons-material/Quiz';
import Link from '@mui/material/Link';

import { APCSMOCK_URL, TEACHABLE_URL, FACEBOOK_URL, TASK_URL, JUDGE_URL } from '../../utilities/const';
import { base64Encode } from '../../utilities/base64';

const Item = (props) => {
  return (
    <Link
      href={props.href}
      underline="none"
      color="inherit"
    >
      <ListItem button>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.label} />
      </ListItem>
    </Link>
  )
}
export const linkItems = (
  <>
    <Item href={TEACHABLE_URL} icon={<SchoolIcon />} label="課程網站" />
    <Item href={TASK_URL} icon={<AssignmentIcon />} label="題單系統" />
    <Item href={JUDGE_URL} icon={<GavelIcon />} label="解題系統" />
    <Item href={APCSMOCK_URL} icon={<QuizIcon />} label="APCS 模擬系統" />
    <Item href={FACEBOOK_URL} icon={<FacebookIcon />} label="粉絲專頁" />
  </>
);

export const secondaryListItems = (
  <>
    <Item href="/profile" icon={<AccountCircleIcon />} label="個人資料設定" />
    <Item href={
      `/api/auth/logout?redirect=${base64Encode(window.location.href)}`
    } icon={<LogoutIcon />} label="登出" />
  </>
);