import { useAuth } from '../../contexts/global/Auth';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { Redirect } from 'react-router';

import GoogleLogin from 'react-google-login';
import GoogleIcon from '@mui/icons-material/Google';

import ColorModeController from '../../components/ColorModeController'

import { useSnackbar } from "notistack";
import { base64Decode } from '../../utilities/base64';
import { REDIRECT_PREVIEW, PORTAL_URL } from '../../utilities/const';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.algoseacow.com/">
        演算法海牛
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const ImageGrid = () => 
  <Grid
    item
    xs={false}
    sm={6}
    md={7}
    sx={{
      height: '100vh',
      backgroundImage: 'url(https://i.imgur.com/6U5Zr8U.png)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[900]

    }}
  />

const RedirectText = () => {
  const { search } = window.location;
  const query = new URLSearchParams(search);
  const redirect = query.get('redirect');
  if (!redirect)
    return <></>
  const location = new URL(base64Decode(redirect));
  if (location.origin === PORTAL_URL)
    return <></>
  return (
    <Typography variant="subtitle1">
      { REDIRECT_PREVIEW[location.origin] ? `即將前往 ${REDIRECT_PREVIEW[location.origin]}` : `即將前往 未知的外部連結` }
    </Typography>
  )
}
const LoginPanel = () => {
  const { login } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const handleSuccess = login;
  const handleFailure = (result) => {
    enqueueSnackbar(result.error, { variant: 'error' });
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Avatar sx={{ m: 2 }} color="primary">
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        演算法海牛 單一入口
      </Typography>
      <RedirectText />
      <Box sx={{ mt: 2 }}>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          cookiePolicy={'single_host_origin'}
          render={renderProps => (
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                m: '5px 0px',
                borderRadius: 10,
                textTransform: 'none',
                display: 'flex',
                justifyContent: 'center',
              }}
              fullWidth
              startIcon={<GoogleIcon />}
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <Typography variant='subtitle2'>
                {`透過 Google 登入`}
              </Typography>
            </Button>
          )}
        />
      </Box>
    </Box>
  )
}

const LoginGrid = () =>
  <Grid
    item
    xs={12}
    sm={6}
    md={5}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[900]
    }}
    component={Paper}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <ColorModeController />
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoginPanel />
    </Box>
    <Box sx={{ m: 2 }}>
      <Copyright />
    </Box>
  </Grid>

export default function Login() {
  const { auth } = useAuth();

  if (auth.isAuthenticated)
    return <Redirect to="/" />

  return (
    <Grid container>
      <ImageGrid />
      <LoginGrid />
    </Grid>
  )
}