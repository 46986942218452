import { createContext, useContext, useEffect, useState } from "react"
import { useApi } from "./Api";
import Loading from "../../components/Loading";
import Box from '@mui/material/Box';

import { base64Encode, base64Decode } from '../../utilities/base64'; 

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export default function Auth({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    profile: null
  })

  const { req } = useApi();
  useEffect(() => {
    req("get", "/api/auth/profile").then( res => {
      setAuth({
        isAuthenticated: true,
        profile: res.data
      });
      setIsLoading(false)
    }, () => {
      const { pathname, href } = window.location;
      if (pathname !== "/login")
        window.location.assign(`/login?redirect=${base64Encode(href)}`)
      else
        setIsLoading(false)
    })
  }, [])

  const login = ({ tokenId }) => {
    const { search, origin } = window.location;
    const query = new URLSearchParams(search);
    const redirect = query.get('redirect') || base64Encode(origin)
    window.location.assign(`/api/auth/login?tokenId=${tokenId}&redirect=${redirect}`)
  }

  if (isLoading)
    return (
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <Loading />
      </Box>
    )

  return (
    <AuthContext.Provider value={{ auth, login }}>
      { children }
    </AuthContext.Provider>
  )
}