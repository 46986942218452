import ApiProvider from './Api';
import ThemeProvider from './Theme';
import { SnackbarProvider } from 'notistack';
import AuthProvider from './Auth';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { createRef } from 'react';
import Fade from '@mui/material/Fade';

export default function GlobalProvider({ children }) {
  const notistackRef = createRef()
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }
  
  return (
    <ThemeProvider>
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate
        ref={notistackRef}
        action={(key) => (
          <IconButton size="small" onClick={onClickDismiss(key)} sx={{ color: 'white' }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        TransitionComponent={Fade}
      >
        <ApiProvider>
          <AuthProvider>
            {children}
          </AuthProvider>
        </ApiProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}