import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"

export default function NotFound() {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h1">403</Typography>
      <Typography varient="subtitle1">您沒有權限訪問此網站</Typography>
      <Link href="/">回首頁</Link>
    </Box>
  );
}
